import { graphql, Link } from 'gatsby'
import React, { Component } from "react"
import { Alert, Carousel, Jumbotron } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ConditionalWrap from "../util/ConditionalWrap"
import NonStretchedImage from "../util/NonStretchedImage"

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let carousel_height
    let display_items = [...this.props.data.gallery.yaml.gallery, ...this.props.data.research.yaml.research].sort((a, b) => {
      return a.priority - b.priority;
    })

    if (this.state.width < 330) {
      carousel_height = 200
    } else if (this.state.width < 480) {
      carousel_height = 240
    } else if (this.state.width < 650) {
      carousel_height = 310
    } else {
      carousel_height = 380
    }

    return (
      <Layout>
        <style>
          {`
            .carousel-control-next,
            .carousel-control-prev /*, .carousel-indicators */ {
                filter: invert(100%);
            }
          `}
        </style>
        <SEO title="Home" keywords={[this.props.data.site.siteMetadata.title, this.props.data.site.siteMetadata.pi]} />
        <Carousel className='mt-4' interval={4000}>
          {display_items.map((item, i) => {
            return (
              <Carousel.Item key={i} style={{ height: carousel_height }}>
                <NonStretchedImage
                  className="d-block w-100" alt={item.name}
                  fluid={item.images[0].childImageSharp.fluid} />
                <ConditionalWrap
                  condition={item.slug}
                  wrap={children => <Link to={`/research#${item.slug}`}>{children}</Link>}>
                  <Carousel.Caption className='m-0 pt-0 px-0 text-light bg-dark'>
                    <h5>{item.name}</h5>
                  </Carousel.Caption>
                </ConditionalWrap>
              </Carousel.Item>
            )})}
        </Carousel>
        <Jumbotron className='mb-2 p-3'>
          <strong>Welcome to the {this.props.data.site.siteMetadata.title} at {this.props.data.site.siteMetadata.institution}!</strong> {this.props.data.site.siteMetadata.description}
        </Jumbotron>
        <Alert variant='primary'>
          We are always looking for highly motivated individuals to <Link to='/openings' className='alert-link'>join our group</Link>.
        </Alert>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
fragment ResearchEntry on DataYaml {
  research {
    slug
    name
    priority
    references
    description
  }
}
query {
  site {
    siteMetadata {
      title
      description
      pi
      institution
    }
  }
  gallery: file(name: {eq: "index"}) {
    yaml: childDataYaml {
      gallery {
        name
        priority
        images {
          childImageSharp {
            fluid(maxHeight: 300) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
  research: file(name: {eq: "research"}) {
    yaml: childDataYaml {
      ...ResearchEntry
      research {
        images {
          childImageSharp {
            fluid(maxHeight: 300) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
}
`
